<div class="content-wrapper">
  <div class="content-wrapper-main-inner-content">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="ps-3">Attachments</h6>
      <div class="dropdown" style="z-index: 99;">
        <button class="btn theme-border-color dropdownButton" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
          + Dropdown button
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item">Action</a>
          <a class="dropdown-item">Another action</a>
          <a class="dropdown-item">Something else here</a>
        </div>
      </div>
    </div>
    <div>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>

        </tbody>
      </table>
    </div>
  </div>
</div>
