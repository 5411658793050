<div class="content-wrapper">
  <div class="ps-3 mt-0 d-flex align-items-center">
    <div class="pe-3">
      <img style="border-radius: 50%; width:100px; height: 100px"
           [src]="'../../../../../../assets/images/maleDefault.jpg'" alt="logo"/>
    </div>
    <div class="d-flex flex-column">
      <h6></h6>
      <div>
        <span style="font-weight: 500;">Name</span> : <span class="theme-text-color">{{ customerInfoData?.firstName ?? '' }} {{ customerInfoData?.lastName ?? '' }}</span>
      </div>
      <div>
        <span style="font-weight: 500;">Title</span> : <span class="theme-text-color">{{ customerInfoData?.title ?? '' }}</span>
      </div>
      <div>
        <span style="font-weight: 500;">Experience</span> :
        <span class="theme-text-color">{{ customerInfoData?.experienceYear ?? '' }} Years {{ customerInfoData?.experienceMonth ?? '' }}
          Months</span>
      </div>
    </div>
  </div>
  <div class="ps-3 pt-1 " style="font-size: 14px">
    <span  style="font-weight: 400;">Submitted On</span>
    {{ customerInfoData?.appliedDate | date:'  dd MMM yyyy' }}
    <span  style="font-weight: 400;">Updated On:</span>
    {{ customerInfoData?.updatedDate | date:'  dd MMM yyyy' }}
  </div>
</div>
