import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attachement-component',
  templateUrl: './attachement-component.component.html',
  styleUrls: ['./attachement-component.component.css']
})
export class AttachementComponentComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
