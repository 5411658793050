import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from "../../../../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {Candidate, PlaceDto} from "../../../../shared/interfaces";
import {ToastrService} from "ngx-toastr";
import {Subject} from "rxjs";
import {debounceTime, delay, distinctUntilChanged, filter, map, takeUntil, tap} from "rxjs/operators";

interface FormField {
  label: string;
  type: string;
  required: boolean;
  formControlName: string;
}

@Component({
  selector: 'app-web-candidate-information',
  templateUrl: './web-candidate-information.component.html',
  styleUrls: ['./web-candidate-information.component.css']
})
export class WebCandidateInformationComponent implements OnInit, OnChanges {
  @Input() customerInfoData: Candidate;
  @Input() formFields!: FormField[];
  @Output() handleInfoFormSubmit = new EventEmitter<any>();
  candidateForm: FormGroup;
  experienceYears: number[] = [];
  experienceMonths: number[] = [];
  candidateId: number;
  countryControl = new FormControl();
  stateControl = new FormControl('');
  cityControl = new FormControl('');
  countryServerCtrl = new FormControl();
  stateServerCtrl = new FormControl();
  cityServerCtrl = new FormControl();
  selectedCountry: any | undefined;
  selectedState: any | undefined;
  selectedCity: any | undefined;
  searchCountry: boolean = false;
  searchState: boolean = false;
  searchCity: boolean = false;
  countries: PlaceDto[] = [];
  states: PlaceDto[] = [];
  cities: PlaceDto[] = [];
  protected _onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.candidateId = this.route.snapshot.params['id'];
    this.experienceYears = Array.from({length: 31}, (_, i) => i);
    this.experienceMonths = Array.from({length: 12}, (_, i) => i);
    this.initializeForm();
    this.onSearchCountry();
    this.onSearchState();
    this.onSearchCities();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.customerInfoData) {
      this.patchFormData();
    }
  }

  initializeForm(): void {
    const formGroupConfig: { [key: string]: any } = {};
    this.formFields.forEach((field: FormField) => {
      formGroupConfig[field.formControlName] = ['', field.required ? [Validators.required] : []];
    });
    formGroupConfig['currentLocation'] = this.fb.group({
      streetAddress: ['', Validators.required],
      postalCode: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
      country: this.fb.group({
        id: [null],
        name: [null],
        formattedAddress: [null],
      }),
      state: this.fb.group({
        id: [null],
        name: [null],
        formattedAddress: [null]
      }),
      city: this.fb.group({
        id: [null],
        name: [null],
        formattedAddress: [null]
      }),
    });
    this.candidateForm = this.fb.group(formGroupConfig);
  }

  patchFormData(): void {
    if (this.customerInfoData && this.candidateForm) {
      this.candidateForm.patchValue({
        firstName: this.customerInfoData.firstName,
        lastName: this.customerInfoData.lastName,
        title: this.customerInfoData.title,
        experienceYear: this.customerInfoData.experienceYear,
        experienceMonth: this.customerInfoData.experienceMonth,
        mobile: this.customerInfoData.mobile,
        email: this.customerInfoData.email,
        linkedInURL: this.customerInfoData.linkedInURL,
        currentCtc: this.customerInfoData.currentCtc,
        expectedCtc: this.customerInfoData.expectedCtc,
      });
      if (this.customerInfoData.currentLocation.country) {
        this.selectedCountry = this.customerInfoData.currentLocation.country;
        this.countries = [this.selectedCountry];
        this.candidateForm.get('currentLocation.country')?.patchValue(this.selectedCountry);
        this.countryControl.setValue(this.selectedCountry);
      }

      if (this.customerInfoData.currentLocation.state) {
        this.selectedState = this.customerInfoData.currentLocation.state;
        this.states = [this.selectedState];
        this.candidateForm.get('currentLocation.state')?.patchValue(this.selectedState);
        this.stateControl.setValue(this.selectedState);
      }

      if (this.customerInfoData.currentLocation.city) {
        this.selectedCity = this.customerInfoData.currentLocation.city;
        this.cities = [this.selectedCity];
        this.candidateForm.get('currentLocation.city')?.patchValue(this.selectedCity);
        this.cityControl.setValue(this.selectedCity);
      }
      const {streetAddress, postalCode} = this.customerInfoData.currentLocation;
      if (streetAddress) {
        this.candidateForm.get('currentLocation.streetAddress')?.patchValue(streetAddress);
      }

      if (postalCode) {
        this.candidateForm.get('currentLocation.postalCode')?.patchValue(postalCode);
      }
    }
  }

  onSearchCountry() {
    this.countryServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCountry = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCountries(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchCountry = false;
      }, error: err => {
        this.searchCountry = false;
      }
    });
  }

  onSearchCities() {
    this.cityServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchCity = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getCities(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchCity = false;
      }, error: err => {
        this.searchCity = false;
      }
    });
  }

  onSearchState() {
    this.stateServerCtrl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searchState = true),
      takeUntil(this._onDestroy),
      debounceTime(500),
      distinctUntilChanged(),
      map(search => {
        return this.getStates(search)
      }),
      delay(500),
    ).subscribe({
      next: filteredCountries => {
        this.searchState = false;
      }, error: err => {
        this.searchState = false;
      }
    });
  }

  getCities(query: string): void {
    this.apiService.getCities(this.candidateForm?.get("currentLocation")?.get("state")?.get('id')?.value, query).then(res => {
      this.cities = this.selectedCity ? [this.selectedCity, ...res] : res;
    });
  }

  getCountries(query: string): void {
    this.apiService.getCountries(query).then((res: any) => {
      this.countries = this.selectedCountry ? [this.selectedCountry, ...res] : res;
      return (res);
    });
  }

  getStates(query: string): void {
    this.apiService.getStates(this.candidateForm?.get("currentLocation")?.get("country")?.get('id')?.value, query).then(res => {
      this.states = this.selectedState ? [this.selectedState, ...res] : res;
    });
  }


  selectCountry(country: PlaceDto): void {
    this.candidateForm?.get("currentLocation")?.get("country")?.setValue(country);
    this.selectedState = undefined;
    this.selectedCity = undefined;
    this.selectedCountry = country;
    this.candidateForm?.get("currentLocation")?.get("state")?.reset();
    this.candidateForm?.get("currentLocation")?.get("city")?.reset();
  }

  selectState(state: PlaceDto): void {
    this.candidateForm?.get("currentLocation")?.get("state")?.get('id')?.setValue(state.id);
    this.candidateForm?.get("currentLocation")?.get("state")?.get('name')?.setValue(state.name);
    this.candidateForm?.get("currentLocation")?.get("state")?.get('formattedAddress')?.setValue(state.formattedAddress);
    this.selectedState = state;
    this.selectedCity = undefined;
    this.candidateForm?.get("currentLocation")?.get("city")?.reset();
  }

  selectCity(city: PlaceDto): void {
    this.candidateForm?.get("currentLocation")?.get("city")?.setValue(city);
  }

  compareFunction(optionValue: PlaceDto, selectedValue: PlaceDto): boolean {
    return optionValue && selectedValue && optionValue === selectedValue;
  }

  Submit() {
    this.handleInfoFormSubmit.emit(this.candidateForm)
  }

}
