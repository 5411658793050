<div class="content-wrapper">
  <div class="content-wrapper-main-inner-content">
    <h6 class="ps-3">Communication</h6>
    <div class="d-flex col">
      <div class=" w-100 ">
        <label class="w-100 search-input">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput>
          </mat-form-field>
        </label>
      </div>
      <div class="col-2 w-100 mt-2 pt-1">
        <button class="btn btn-save theme-btn-color text-white ps-sm-5 pe-sm-5 pt-sm-2 pb-sm-2">Save</button>
      </div>
    </div>
    <div>

    </div>
  </div>
</div>
