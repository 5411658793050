<div class="content-wrapper">
  <form [formGroup]="candidateForm">
    <div class="row">
      <div *ngFor="let field of formFields" class="col-md-4">
        <mat-form-field *ngIf="field.label === 'Experience Years' || field.label === 'Experience Months'"
                        appearance="outline" class="w-100">
          <mat-label>{{ field.label }}</mat-label>
          <mat-select [formControlName]="field.formControlName">
            <mat-option *ngFor="let option of (field.label === 'Experience Years' ? experienceYears : experienceMonths)"
                        [value]="option">{{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="field.label !== 'Experience Years' && field.label !== 'Experience Months'"
                        appearance="outline" class="w-100">
          <mat-label>{{ field.label }}</mat-label>
          <input
            matInput
            [type]="field.type"
            [formControlName]="field.formControlName"
            [required]="field.required">
        </mat-form-field>
      </div>
    </div>

    <div formGroupName="currentLocation">
      <h2 class="mb-4">Address Information</h2>

      <div class="form-row mb-2">
        <div class="col-md-8  ms-0">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Street Address</mat-label>
            <input matInput placeholder="Street Address" formControlName="streetAddress">
          </mat-form-field>
        </div>
        <div class="col-md-4 ms-0">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>PinCode</mat-label>
            <input matInput placeholder="PinCode" formControlName="postalCode" maxlength="6">
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <div formGroupName="country" class="form-group col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>Country</mat-label>
            <mat-select [formControl]="countryControl" [value]="selectedCountry" [compareWith]="compareFunction"
                        (valueChange)="selectCountry($event)" placeholder="Country">
              <mat-option>
                <ngx-mat-select-search [formControl]="countryServerCtrl" [searching]="searchCountry"
                                       [placeholderLabel]="'Search Country Name...'"
                                       [noEntriesFoundLabel]="'No Result Found'">
                  <mat-icon ngxMatSelectSearchClear>
                    <div class="d-flex align-items-center justify-content-center mt-sm-1">
                      <a>
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </a>
                    </div>
                  </mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of countries" [value]="country">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div formGroupName="state" class="form-group col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>State</mat-label>
            <mat-select [formControl]="stateControl" [value]="selectedState" [compareWith]="compareFunction"
                        (valueChange)="selectState($event)" placeholder="State"
                        [disabled]="!selectedCountry">
              <mat-option>
                <ngx-mat-select-search [formControl]="stateServerCtrl" [searching]="searchState"
                                       [placeholderLabel]="'Search State Name...'"
                                       [noEntriesFoundLabel]="'No Result Found'">
                  <mat-icon ngxMatSelectSearchClear>
                    <div class="d-flex align-items-center justify-content-center mt-sm-1">
                      <a>
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </a>
                    </div>
                  </mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of states" [value]="state">
                {{ state.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div formGroupName="city" class="form-group col-md-4">
          <mat-form-field appearance="outline" class="w-100 border-red">
            <mat-label>City</mat-label>
            <mat-select [formControl]="cityControl" [value]="selectedCity" (valueChange)="selectCity($event)"
                        [compareWith]="compareFunction"
                        placeholder="City" [disabled]="!selectedState">
              <mat-option>
                <ngx-mat-select-search [formControl]="cityServerCtrl" [searching]="searchCity"
                                       [placeholderLabel]="'Search City Name...'"
                                       [noEntriesFoundLabel]="'No Result Found'">
                  <mat-icon ngxMatSelectSearchClear>
                    <div class="d-flex align-items-center justify-content-center mt-sm-1">
                      <a>
                        <svg width="14" height="14" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2872 1L1 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          <path d="M1 1L17.2872 17.2871" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                      </a>
                    </div>
                  </mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of cities" [value]="city">
                {{ city.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-end">
      <button type="button" class="btn theme-btn-color text-white" (click)="Submit()">Save Details</button>
    </div>
  </form>
</div>
