import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {Candidate, Consultant, Skill} from "../../../../shared/interfaces";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatDatepicker} from "@angular/material/datepicker";
import {ApiService} from "../../../../services/api.service";
import {DataService} from "../../../../services/data.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {DatePipe} from "@angular/common";
import {debounce} from "lodash";
import {debounceTime, distinctUntilChanged, filter, map, startWith, tap} from "rxjs/operators";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {fromEvent} from "rxjs";
import {ResetFiltersService} from "../../../../services/resetFilters.service";

@Component({
  selector: 'app-web-candidate-table-listing',
  templateUrl: './web-candidate-table-listing.component.html',
  styleUrls: ['./web-candidate-table-listing.component.css']
})
export class WebCandidateTableListingComponent implements OnInit {
  searchFieldName: string[] = ['candidateName', 'title', 'ExperienceYear', 'skills', 'locationsPref', 'dropdownNoticePeriod', 'statusWebCustomer', 'dateSearchCriteria',]
  filters = {
    dateSearchCriteria: {
      filterType: 'CREATED',
      from: '',
      to: '',
    },
    locationPreference: [],
    noticePeriod: [],
    status: [],
    skills: [],
    candidateName: '',
    query: '',
    title: '',
  };
  candidates: Candidate[] = [];
  currentPage = 0;
  showLoader: boolean = true;
  search = '';
  pageSize = 12;
  totalEmployee = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  constructor(
    private service: ApiService,
    public snackBar: MatSnackBar,
    public dialog: NgDialogAnimationService,
    private resetFilters: ResetFiltersService,
  ) {
  }

  ngOnInit(): void {
    this.showLoader = false;
    this.getAllWebCandidates(this.currentPage, this.pageSize, [], this.filters);
    this.getAllWebCandidates = debounce<any>(this.getAllWebCandidates, 600);
  }

  onFiltersChanged(updatedFilters: any) {
    console.log('updatedFilters', updatedFilters)
    const filteredFilters = {...updatedFilters};
    delete filteredFilters.locationsPref;
    this.filters = {
      ...filteredFilters,
      query: this.search,
      locationPreference: updatedFilters?.locationsPref ?? []
    };
    this.paginator.pageIndex = 0;
    this.filters.query = this.search;
    this.getAllWebCandidates(this.currentPage, this.pageSize, [], this.filters);
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllWebCandidates(this.currentPage, this.pageSize, [], this.filters);
  }

  updateSearch() {
    this.filters.query = this.search;
    this.paginator.pageIndex = 0;
    this.getAllWebCandidates(this.currentPage, this.pageSize, [], this.filters);
  }

  getAllWebCandidates(page: number, size: number, sort: string[], payload: any): void {
    this.showLoader = true;
    this.service.getWebCandidate(page, size, sort, payload).subscribe({
      next: res => {
        this.candidates = res.content;
        this.paginator.pageIndex = res.page.number;
        this.paginator.pageSize = res.page.size;
        this.pageSize = res.page.size;
        this.totalEmployee = res.page.totalElements;
        this.showLoader = false;
      },
      error: err => {
        console.log('error', err);
        this.showLoader = false;
      },
      complete: () => {
        this.showLoader = false;
      }
    });
  }

  getSkills(skills: any): { skillsString: string, pendingSkillsCount: number, pendingSkills: string[] } {
    let skillsString = '';
    let count = 0;
    let pendingCount = 0;
    let pendingSkills: string[] = [];

    skills.forEach((skill: any, index: number) => {
      if (count < 5) {
        skillsString += skill.title;
        if (count < 4 && index < skills.length - 1) {
          skillsString += ' | ';
        }
        count++;
      } else {
        pendingSkills.push(skill.title);
        pendingCount++;
      }
    });

    return {
      skillsString: skillsString,
      pendingSkillsCount: pendingCount,
      pendingSkills: pendingSkills
    };
  }


  clearFilter() {
    this.filters = {
      dateSearchCriteria: {
        filterType: 'CREATED',
        from: '',
        to: '',
      },
      locationPreference: [],
      noticePeriod: [],
      status: [],
      skills: [],
      candidateName: '',
      query: '',
      title: '',
    };
    this.resetFilters.emitChange(true);
    this.getAllWebCandidates(0, this.pageSize, [], this.filters);
  }

  formatLocationData(data: any) {
    if (Array.isArray(data)) {
      return data.map(item => item.location);
    }
    return [];
  }


}
