import {Component, OnInit, AfterViewInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NgxSpinnerService} from "ngx-spinner";
import {DataService} from "./services/data.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, AfterViewInit {
  constructor(private titleService: Title,
              private spinner: NgxSpinnerService,
              private data: DataService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Vendor Portal');
    if (this.data.isLoading) {
      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
    }
  }

  ngAfterViewInit() {
    this.data.isLoading.next(true);
    if (window.performance.navigation.type === 1) {
      // This function runs only on app reload
      this.spinner.show();
    }
  }


}
