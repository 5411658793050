<div class="content-wrapper">
  <div class="content-wrapper-main-inner-content">
    <div *ngIf="openDialogType !='READONLY'" class="vendor_wrapper">
      <h6 class="mb-3 ps-3">Skills</h6>
      <div *ngIf="openDialogType === 'READONLY'">
        <div *ngIf="skills.length > 0" class="vendor_wrapper">
          <span>Skills</span>
          <div>
            <mat-chip
              class="mb-3 me-3"
              *ngFor="let skill of skills; let i= index"
              (removed)="openDialogSkillDelete(skill.id,i,skill.title)">
              {{ skill.title }}
              <button [disabled]="true" matChipRemove><span>&#x2716;</span></button>
            </mat-chip>
          </div>
        </div>
      </div>
      <div *ngIf="skills.length > 0" class="ps-3">
        <div>
          <mat-chip
            class="mb-3 me-3"
            *ngFor="let skill of skills; let i= index"
            (removed)="openDialogSkillDelete(skill.id,i,skill.title)">
            {{ skill.title }}
            <button matChipRemove><span>&#x2716;</span></button>
          </mat-chip>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-10">
          <div *ngIf="(filteredSkills|async)?.length !== 0 && !exactMatchFound && skillControl?.value"
               class="dropdown-skills position-relative">
            <ul>
              <li class="font-weight-bold">
                <div class="d-flex justify-content-between align-items-center" (click)="addSkillType()">
                  <span>Add this Skill to Skill Types</span>
                  <button class="p-0 bg-transparent border-0 fs-20"> +</button>
                </div>
              </li>
            </ul>
          </div>
          <mat-form-field appearance="outline" class="w-100 border-red skills_drop">
            <mat-label>Skills</mat-label>
            <mat-chip-list #chipList aria-label="skill selection">
              <mat-chip
                *ngFor="let skill of selectedSkill"
                (removed)="removeSkill(skill)">
                {{ skill.title }}
                <button matChipRemove><span>&#x2716;</span></button>
              </mat-chip>
              <input #chipGrid
                     placeholder="Add your skills..."
                     [formControl]="skillControl"
                     [matAutocomplete]="auto"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [(ngModel)]="skillName"
                     #autoTrigger='matAutocompleteTrigger'
                     (click)="openAutocomplete(autoTrigger)"
              >
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkills($event)">
              <mat-option *ngFor="let skill of filteredSkills | async" [value]="skill"
                          [disabled]="disableSkill(skill)">
                {{ skill.title }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div *ngIf="(filteredSkills|async)?.length == 0" class="dropdown-skills position-relative">
            <ul>
              <li class="li_disabled">
                No match Found
              </li>
              <hr class="m-0 p-0">
              <li class="font-weight-bold">
                <div class="d-flex justify-content-between align-items-center" (click)="addSkillType()">
                  <span>Add this Skill to Skill Types</span>
                  <button class="p-0 bg-transparent border-0 fs-20"> +</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-2">
          <div class="d-flex align-items-center justify-content-center">
            <div *ngIf="!loading" class="w-100 mt-2 pt-1">
              <button (click)="addSkill()" class="btn btn-save theme-btn-color text-white ps-sm-5 pe-sm-5 pt-sm-2 pb-sm-2">Save</button>
            </div>
            <div *ngIf="loading">
              <button class="btn btn-save theme-btn-color" disabled>
                <div class="spinner-border spinner-border-sm me-2" role="status">
                  <span class="sr-only"></span>
                </div>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
