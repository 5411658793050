import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../services/api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ToastrService} from "ngx-toastr";
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-create-candidate',
  templateUrl: './register-now.html',
  styleUrls: ['./register-now.css']
})

export class RegisterNow implements OnInit {
  registerNowForm: FormGroup;
  displayErrorEmail: boolean = false;
  errorMessageEmail: string | undefined;
  countryCode: string[] = [];
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: ApiService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<RegisterNow>,
  ) {
    this.countryCode = ['+91'];
  }

  ngOnInit(): void {
    this.registerNowForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: [''],
      companyName: ['', Validators.required],
      mobile: ['', [Validators.required, this.mobileNumberValidator]],
      email: ["", [Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+.[a-z]{2,}$")]],
    });
  }

  mobileNumberValidator(control: FormControl): { [key: string]: boolean } | null {
    const mobileNumber = control.value;
    if (!mobileNumber) {
      return null;
    }
    const mobileRegex = /^(\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})$/;
    if (!mobileRegex.test(mobileNumber.e164Number)) {
      return { invalidMobileNumber: true };
    }
    return null;
  }

  close(): void {
    this.dialogRef.close();
  }

  SubmitNow(): void {
    if (this.registerNowForm.valid) {
      let payload = {
        ...this.registerNowForm.value,
        mobile: this.registerNowForm.value?.mobile?.e164Number,
      }
      this.service.registerNow(payload).subscribe(
        (response) => {
          this.toastr.success('Registration successful', 'Success');
          console.log('Response:', response);
          this.dialogRef.close();
        },
        (error) => {
          console.error('Error:', error);
          this.toastr.error('Registration failed', 'Error');
        }
      );
    } else {
      this.toastr.error('Please fill all required fields correctly', 'Form Invalid');
      console.log('Form validation errors:', this.registerNowForm.errors);
      this.registerNowForm.markAllAsTouched();
    }
  }

  onKeyPressEmail(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(inputValue)) {
      this.displayErrorEmail = false;
      this.errorMessageEmail = "";
    } else {
      this.displayErrorEmail = true;
      this.errorMessageEmail = "Please enter a valid email address";
    }
  }

}
