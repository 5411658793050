import {Component, Input, OnInit} from '@angular/core';
import {Candidate} from "../../../../shared/interfaces";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-common-head-component',
  templateUrl: './common-head-component.component.html',
  styleUrls: ['./common-head-component.component.css']
})
export class CommonHeadComponentComponent implements OnInit {
  @Input() customerInfoData: Candidate;

  constructor(
    private date : DatePipe,
  ) {
  }

  ngOnInit(): void {
  }


}
