import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {Candidate} from "../../../../shared/interfaces";
import {ToastrService} from "ngx-toastr";
import {FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";

@Component({
  selector: 'app-web-candidates',
  templateUrl: './web-candidates.component.html',
  styleUrls: ['./web-candidates.component.css']
})
export class WebCandidatesComponent implements OnInit {
  candidateData: Candidate;

  formFields = [
    {label: 'First Name', type: 'text', required: true, formControlName: 'firstName'},
    {label: 'Last Name', type: 'text', required: true, formControlName: 'lastName'},
    {label: 'Title', type: 'text', required: true, formControlName: 'title'},
    {label: 'Experience Years', type: 'number', required: true, formControlName: 'experienceYear'},
    {label: 'Experience Months', type: 'number', required: true, formControlName: 'experienceMonth'},
    {label: 'Phone Number', type: 'tel', required: true, formControlName: 'mobile'},
    {label: 'Email', type: 'email', required: true, formControlName: 'email'},
    {label: 'LinkedIn URL', type: 'url', required: true, formControlName: 'linkedInURL'},
    {label: 'CTC', type: 'text', required: true, formControlName: 'currentCtc'},
    {label: 'ECTC', type: 'text', required: true, formControlName: 'expectedCtc'},
  ];

  constructor(private service: ApiService,
              private route: ActivatedRoute,
              private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.params['id'];
    if (id != null) {
      this.getSingleJobCandidate(Number(id))
    }
  }

  getSingleJobCandidate(jobCandidateId: number): void {
    this.service.getSingleJobCandidate(jobCandidateId).subscribe((res: any) => {
      this.candidateData = res;
    })
  }

  handleCandidateFormSubmit(event: FormGroup): void {
    if (event.invalid) {
      this.markAllFieldsAsTouched(event);
      this.toastr.error('Please fix the errors in the form before submitting.');
      return;
    }
    const formData = {
      ...event.value,
      currentLocation: {
        postalCode: event.get('currentLocation')?.get('postalCode')?.value,
        streetAddress: event.get('currentLocation')?.get('streetAddress')?.value,
        cityId: event.get('currentLocation')?.get('city')?.get('id')?.value,
        stateId: event.get('currentLocation')?.get('state')?.get('id')?.value,
        countryId: event.get('currentLocation')?.get('country')?.get('id')?.value,
      },
    };

    this.service.updateSingleJobCandidate(this.route.snapshot.params['id'], formData)
      .subscribe(
        (res) => {
          this.toastr.success('Candidate information updated successfully!');
        },
        (error) => {
          this.toastr.error('Error updating candidate information: ' + error.message);
        }
      );
  }

  markAllFieldsAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.markAllFieldsAsTouched(control);
      }
    });
  }

  handleSkillUpdate(jobCandidateId: number, payload: { skills: number[] }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.service.updateCandidateSkills(this.route.snapshot.params['id'], payload).subscribe(
        (res) => {
          this.toastr.success('Skills updated successfully!');
          resolve(res);
        },
        (error) => {
          this.toastr.error('Error updating skills: ' + error.message);
          reject(error);
        }
      );
    });
  }

}
